import React from 'react';
import styled from 'styled-components';
import GoToTop from '../GoToTop';
import BannerContacto from './BannerContacto';
import Formulario from './Formulario';
import Informacion from './Informacion';


const Contacto = () => {
    return (
        <>
            <BannerContacto />
            <Titulo>Contacto</Titulo>
            <Contenedor>
                <Formulario />
                <Informacion />
            </Contenedor>
            <GoToTop />
        </>
    );
}

const Titulo = styled.h1`
    text-transform: uppercase;
    color: #00296A;
    text-align: center;
    font-size: 3.5rem;
    padding-top: 2vw;
    
`;
const Contenedor = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        margin: 0 auto;
     }
    
`;
export default Contacto;