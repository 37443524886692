import React from 'react';
import styled from 'styled-components';
import GoToTop from '../GoToTop';
import BtnRegresar from './BtnRegresar';
const RecursosFinancieros = () => {
    return ( 
        <div>
            <BtnRegresar />
            <Titulo>WIN-SIAF RECURSOS FINANCIEROS</Titulo>
            <Informacion>
                <p><b>WIN-SIAF RECURSOS FINANCIEROS</b>, es un sistema de Administración Financiera para sector Gobierno, el cual tenemos las versiones para Gobierno Federal y para gobiernos Estatales, mismo que de conformidad con sus requerimientos presenta los siguientes módulos:</p>
                <ul>
                    <li><p>Presupuesto</p></li>
                    <li><p>Contabilidad</p></li>
                    <li><p>Tesorería</p></li>
                </ul>
                <p>Lo anterior, es con el fin de presentar a ustedes nuestros sistemas, y que les permita conocer su operación y desarrollo para que puedan efectuar un adecuado análisis de los mismos.</p>
                <p>Esto es con el fin de que puedan visualizar en los mismos, sus planteamientos y necesidades reales para la correcta operación de actividades hacia el interior de cualquier entidad gubernamental.</p>
                <p>A partir de que el usuario establezca sus reglas contables o códigos contables conforme a lo solicitado por la SHCP, de tal forma que el sistema se parametriza con el fin de cumplir con los requerimientos establecidos por la CONAC y la Ley General de Contabilidad Gubernamental aplicados para el Gobierno Federal, esto último, permite que la entidad se ponga al día en cuanto a este proceso se refiere y se eviten sanciones administrativas innecesarias para el personal.</p>
                <p>El módulo de presupuesto tiene por objetivo la simplificación del proceso de registro presupuestal y la máxima explotación de la información, en un tiempo corto que es indispensable para la toma de decisiones.</p>
                <p>El módulo Presupuestal opera directamente con los documentos definidos en Gestión Administrativa, afectando los diferentes libros presupuéstales que se encuentran registrados y activos como Parámetros Contables en el Módulo Contabilidad General.</p>
                <p>Al igual que la codificación contable, el módulo presupuestal utiliza los Catálogos de Asociados de Negocio (beneficiarios), de Centros de Costo, así como el de Proyectos, para el registro de los movimientos y explotación de la información, liberando al Catálogo de un gran número de cuentas.</p>
                <p>El concepto del sistema está basado en un ambiente agradable (el cual se puede apreciar desde el primer contacto), de fácil manejo (esto se logra mediante sus pantallas las cuales muestran un lenguaje claro y de fácil interpretación), y que permite una operación rápida (mediante la unificación de las pantallas, así como los botones para su operación).</p>
                <p>Control de usuarios. <b>WIN-SIAF</b> controla los accesos a las alternativas de trabajo y la base de datos según sean los atributos de las personas que estén autorizadas para utilizar el sistema, en pocas palabras, existe seguridad de la información a través de usuarios.</p>
                <p>Se cuenta con el seguimiento de los tiempos del presupuesto siguientes</p>
                <ul>
                    <li><p>Registro del Presupuesto Original calendarizado por mes</p></li>
                    <li><p>Registro de Adecuaciones Presupuestales</p></li>
                    <li><p>Registro de Suficiencias Presupuestales (Reservas Presupuestales)</p></li>
                    <li><p>Registro de Contratos Compromisos Presupuestales (enlazado con el módulo de adquisiciones.</p></li>
                    <li><p>Registro de pagos Directos</p></li>
                    <li><p>Control de pagos de nómina (provenientes del módulo de Recursos Humanos, al generar una nómina el sistema se enlaza al módulo presupuestal para su registro y se generan en forma armonizada el registro contable.</p></li>
                    <li><p>Pagos sobre compromisos (para ir devengado los compromisos presupuestales</p></li>
                    <li><p>Control del presupuesto DEVENGADO, el sistema permite leer los CFDIs de las facturas que se emiten a favor una entidad pública que tenga recursos propios, las valida directamente con el SAT y se relaciona con el compromiso presupuestal (si esta referenciado a un contrato/pedido).</p></li>
                    <li><p>Registro de Reintegros presupuestales</p></li>
                    <li><p>Oficios de Rectificación</p></li>
                </ul>
                <p>El sistema presupuestal contempla los procesos armonizados y genera todos los reportes del Sistema de Evaluación de Armonización Contable (SEvAC) para el Gobierno Federal.</p>
                <ul>
                    <li><p>Estado Analítico del Ejercicio del Presupuesto de Egresos en Clasificación Administrativa.</p></li>
                    <li><p>Estado Analítico del Ejercicio del Presupuesto de Egresos en Clasificación Económica (Armonizado).</p></li>
                    <li><p>Estado Analítico del Ejercicio del Presupuesto de Egresos en Clasificación Funcional (Armonizado).</p></li>
                    <li><p>Estado Analítico del Ejercicio del Presupuesto de Egresos en Clasificación por Objeto del Gasto (Armonizado).</p></li>
                    <li><p>Gasto por Categoría Programática.</p></li>
                    <li><p>Ajustes a los sistemas para el Clasificador de Rubros de Ingresos (Armonizado).</p></li>
                    <li><p>Ajustes para el Clasificador de Gasto Armonizado.</p></li>
                    <li><p>Emisión del reporte Estado Analítico de Ingresos en forma periódica (mensual, trimestral, anual, etc.) derivados de los procesos administrativos que operan en tiempo real y que generan registros automáticos y por única vez.</p></li>
                    <h2>MÓDULO DE TESORERÍA.</h2>
                    <p>De los módulo de tesorería se integra con el control presupuestal para generar transferencias y/o cheques de los pagos de los registros del presupuesto ejercido. Generación de Archivos para el SICOP (sistema de contabilidad y presupuesto) del gobierno federal y para el SIAFF (Sistema Integral de Administración Financiera Federal).</p>
                    <p>En el control bancario del sistema permite registrar todos los movimientos de ingresos y egresos de sus movimientos bancarios sin importar las cuentas bancarias.</p>
                    <p>El módulo de Tesorería también cuenta con un control de caja chica, lo que facilita el manejo del fondo revolvente.</p>
                    <p>Con este control, el sistema puede emitir de manera automática y en cualquier momento el formato para el arqueo del fondo de caja chica.</p>
                    <p>Cuenta con un control para la entrega de cheques a los distintos proveedores del Instituto, permitiendo con ello llevar un registro del personal autorizado por los distintos proveedores para recibir los cheques que se generen.</p>
                    <p>Dentro del manejo del área de Tesorería, uno de los movimientos principales que se tiene que considerar es el continuo intercambio de recursos en las distintas cuentas que pudiera manejar la Institución, lo cual se logra de manera sencilla mediante un sub-módulo que permite la realización de estas operaciones.</p>
                    <p>Enlace con el banco, el sistema genera todos los layouts para el pago masivo de los pagos por medio de sus bancos.</p>
                    <h2>MODULO DE CONTABILIDAD</h2>
                    <p>En este Módulo se registran y emiten los diferentes reportes necesarios dentro de la Dirección de  Recursos Financieros, mismos que corresponden en gran parte al sub-módulo de Contabilidad.</p>
                    <p>En el sub-módulo de Contabilidad, el usuario podrá llevar a cabo la múltiple generación de diversos catálogos, mismos que son importantes dado que al tener un buen manejo y control de estos, al usuario le facilitara la emisión de los reportes contables, presupuestales, fiscales, así como la generación de las pólizas contables respectivas.</p>
                    <p>Adicionalmente, es importante mencionar el sistema permite tener actualizada la guía contabilizadora y catálogo de cuentas conforme a lo establecido y autorizado por la SHCP para las entidades de la Administración Pública Federal, así como el acceso al Clasificador por Objeto del Gasto que permite tener siempre la actualización de las partidas Presupuestales a fin de cumplir con los lineamientos establecidos para tal fin en el PEF. Lo que permitirá que la dependencia cumpla con las normas, lineamientos y leyes en esta materia.</p>
                    <p>Además de los reportes contables comunes:</p>
                    <ul>
                        <li><p>Balanza.</p></li>
                        <li><p>Diario.</p></li>
                        <li><p>Mayor Auxiliar.</p></li>
                        <li><p>Estado de Situación Financiera.</p></li>
                        <li><p>Estado de Resultados.</p></li>
                        <li><p>Reportes del Sistema de Evaluación a la Armonización contable</p></li>
                        <ol>
                            <li><p>Estado Analítico de Ingresos.</p></li>
                            <li><p>Estado Analítico del Activo.</p></li>
                            <li><p>Estado Analítico de la Deuda.</p></li>
                            <li><p>Estado de Cambios en Situación Financiera.</p></li>
                            <li><p>Estado de Actividades.</p></li>
                            <li><p>Estado de Situación Financiera.</p></li>
                            <li><p>Estado de Variación en Hacienda Pública.</p></li>
                            <li><p>Flujo de Efectivo.</p></li>
                            <li><p>Libro de Inventarios.</p></li>
                            <li><p>Notas a los Estados Financieros.</p></li>
                            <li><p>Pasivos Contingentes.</p></li>
                        </ol>
                        <li><p>Control de códigos y Guía Contabilizadora del Módulo de Contabilidad.</p></li>
                        <li><p>Generación de pólizas automáticas que provienen de los registros presupuestales y de tesorería.</p></li>
                        <li><p>Generación de la conciliación contable-presupuestal de ingresos y egresos.</p></li>
                        <li><p>Generación de pólizas de los registros generados de las operaciones financieras del Módulo de Bancos.</p></li>
                        <li><p>Desarrollo de mecanismos de control que solicite el área de conformidad con sus políticas de operación internas.</p></li>
                        <li><p>Control de las operaciones pendientes de pago para el tiempo del registro presupuestal ejercido no pagado.</p></li>

                    </ul>

                </ul>
            </Informacion>
            <GoToTop />
        </div>
     );
}

const Titulo = styled.h1`
margin-top: 2vw;
text-align: center;
color:#00296a; 
`;
const Informacion = styled.div`
padding: 2vw;
@media (max-width: 425px) {
            font-size: .80rem;
        }
ul{
    list-style: disc;
}
li{
    color:#00296a; 
    p{
        color: black;
    }
}
p{
    color: black;
    text-align: justify;
}
h2{
    text-align: center;
    color:#00296a; 
}
b{
    color:#00296a; 
}
`;

export default RecursosFinancieros;