import React from 'react';
import styled from 'styled-components';

const Contenido = () => {
    return (
        <ContenedorServicios>
            <p>Para el caso de gobierno contamos con un área de servicios especialmente para el proporcionar la consultoría y mantenimiento a los sistemas administrativos que la empresa ha desarrollado especialmente para el sector público.</p>
            <p><b>En el campo de consultoría apoyamos a las organizaciones en:</b></p>
            <ul>
                <li><p>Alineación de procesos</p></li>
                <li><p>Modelos de operación ad-hoc para la implementación tecnológica</p></li>
                <li><p>Estandarización de procesos adecuándola a la normatividad aplicable</p></li>
                <li><p>Eficiencia administrativa</p></li>
                <li><p>Documentación de políticas y procedimientos</p></li>
                <li><p>Elaboración de guías operativas</p></li>
            </ul>
            <p><b>Un grupo de especialistas, dedicados específicamente atender el sector público o en su caso para el Sector Bancario</b></p>
            <p>Así mismo contamos con un grupo de especialistas que adicionalmente de ser especialistas en el desarrollo de sistemas cuenta con la experiencia y conocimiento normativo en las diferentes áreas de la administración pública Federal, tales como las de Recursos Materiales (adquisiciones, almacenes, activos fijos y servicios generales), Recursos Humanos (Contratación - servicio profesional de carrera, capacitación, Recursos Humanos y Remuneración pagos de nóminas) y (control presupuestal del capítulo 1000) y control Presupuestal y contable, así como en Viáticos y Pasajes. Por lo tanto, nuestros consultores son especialistas en el sector y obviamente se cuenta con la experiencia en la instrumentación de sistemas integrales en la Administración Pública Federal de veinte años de experiencia,</p>
            <p><b>Otros servicios</b></p>
            <p>Al ser fabricantes directos del software mencionado, ofrecemos al usuario apoyo directo y las adecuaciones necesarias para cubrir sus requerimientos. Es por ello, que en los 20 años que llevamos inmersos en el competitivo mercado de servicios de cómputo tenemos clientes e importantes empresas que avalan la calidad y eficiencia de nuestro trabajo.</p>
            <p>La calidad de nuestros sistemas, ha ido adquiriendo cada vez mayor prestigio entre nuestros clientes, gracias a que al obtener un programa con los mejores resultados, se va ampliando la implementación de otros sistemas para cubrir integralmente los requerimientos de su empresa o Institución. Por ejemplo, varios de nuestros clientes tienen operando más de un sistema de <b>MW Software</b>, ya sea de inventarios o de bancos o contabilidad y/o nómina, o todos los anteriores.</p>
            <p>Contamos con un área de servicios especializada en proporcionar la consultoría y mantenimiento a los sistemas administrativos que la empresa ha desarrollado, principalmente para el sector Gobierno Federal.</p>
            <p>Un grupo de especialistas, dedicados específicamente atender el sector público o en su caso el Sector Bancario  </p>
            <p>Entre otros servicios podemos mencionar los siguientes:  </p>
            <ContenedorListado>
                <div>
                    <ul>
                        <li><p>Consultoría normativa vigente.</p></li>
                        <li><p>Desarrollo de sistemas administrativos.</p></li>
                        <li><p>Mantenimiento y Soporte técnico en los sistemas desarrollados por la empresa.</p></li>
                        <li><p>Captura de datos.</p></li>
                        <li><p>Migración de información.</p></li>
                        <li><p>Levantamiento de Inventarios Físicos de Activo y Consumos.</p></li>
                        <li><p>Evaluación al Desempeño.</p></li>
                        <li><p>Organización del área contable de todo tipo de empresas.</p></li>
                        <li><p>Desarrollo de planes de cuentas contables.</p></li>
                        <li><p>Administración de expedientes de personal.  </p></li>
                        <li><p>Elaboración de nóminas de conformidad a las características de su empresa.</p></li>
                        <li><p>Asesoría en materia laboral, prestaciones, indemnizaciones y beneficios.</p></li>
                        <li><p>Dictaminarían y auditorías en materia de IMSS e INFONAVIT.</p></li>
                        <li><p>Revisión del cumplimiento en materia del Impuesto Sobre Nóminas, en cada una de las Entidades Federativas.</p></li>
                        <li><p>Capacitación con fines de certificación para servidores públicos en dependencias y entidades, ofreciendo entre otros, los siguientes cursos: o  Recursos Humanos. o Relaciones Laborales. o Selección e Ingreso.</p></li>
                        <li><p>Adquisiciones, Arrendamientos y Servicios Generales</p></li>
                        <li><p>Registro de operaciones, análisis, conciliación y depuración de cuentas contables.</p></li>
                    </ul>
                </div>
                <div>
                    <ul>
                    <li><p>Regularización de los registros contables de su empresa.  </p></li>
                    <li><p>Implementación de la facturación electrónica.</p></li>
                    <li><p>Emisión, interpretación y dictaminarían de estados financieros.</p></li>
                    <li><p>Asistencia administrativa</p></li>
                    <li><p>Inventarios físicos</p></li>
                    <li><p>Realización de “trabajos especiales” en materia contable administrativa.</p></li>
                    <li><p>Elaboración y revisión de procesos administrativos, tales como manuales de organización, manuales de procedimientos, organigramas, etc.</p></li>
                    <li><p>Planeación y elaboración de estrategias fiscales, con base en la normatividad vigente y en sus posibles cambios.</p></li>
                    <li><p>Verificación y determinación de la carga impositiva de su empresa, de acuerdo a las leyes fiscales, tanto mensuales, como anuales.</p></li>
                    <li><p>Asesoría en trámites fiscales.</p></li>
                    <li><p>Atención de requerimientos de autoridad.</p></li>
                    <li><p>Obtención de beneficios y estímulos fiscales.  </p></li>
                    <li><p>Asesoría en auditorías de las distintas instancias fiscalizadoras</p></li>
                    <li><p>Auditoría de estados financieros para efectos fiscales.</p></li>
                    <li><p>Diseño de políticas en materia laboral y contratos laborales.</p></li>
                    <li><p>Atención a revisiones de auditoría en materia de seguridad social.</p></li>


                    </ul>
                </div>
            </ContenedorListado>
        </ContenedorServicios>
    );
}

const ContenedorServicios = styled.div`
    padding: 2vw;
    p b{
        color:#00296a; 
    }
    li{
        color:#00296a; 
        p{
            color: black;
        }
    }
    
`;

const ContenedorListado = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vw;
    @media (min-width: 325px) and (max-width: 600px) {
            flex-direction: column;
    }
`;

export default Contenido;