import React from 'react';
import { NavLink } from 'react-router-dom';
import productos from '../../data/productos';
import styled from 'styled-components';
import Logo from '../../assets/logo.png'


const Productos = () => {
    return ( 
        <ContenedorProductos>
            <Titulo>WIN-SIAF</Titulo>
            <Subtitulo >WIN-SIAF  es  un  GRP par a las  instituciones  del  sector  público. Cumple con  la Ley  General  de  Contabilidad Gubernamental  en temas  de  armonización  contable para Recursos  Financieros ,  Recursos  Humanos  y  Recursos  Materiales  para cualquier ente público.</Subtitulo>
            <NavLink to="/winsiaf"><BtnConoce data-font="&#xf109;" >¿Qué es WIN-SIAF?</BtnConoce> </NavLink>
            <ContenedorCards>
                {productos.map((producto) => {
                    return (
                        <Card key={producto.id}>
                            <ImagenCard src={producto.imagen} />
                            <TituloCard>{producto.titulo }</TituloCard>
                            <ModulosCards>{producto.modulo}</ModulosCards>
                            <NavLink to={`/productos/${producto.slug}`}><BtnConoce data-font="&#xf0a9;" >Ir al producto</BtnConoce></NavLink>
                        </Card>    
                    );
                })}
                
            </ContenedorCards>
        </ContenedorProductos>
    );
}

const ContenedorProductos = styled.div`
    background-color: #fff;
`;
const Titulo = styled.h1`
    color:#00296a;
    text-align: center;
    padding-top: 2vw;
    font-size: 4rem;
`;
const Subtitulo = styled.p`
        color:#00296a;
        text-align: center;
        margin: 0 auto;
        padding: 2vw 10vw;
`;
const BtnConoce = styled.button`
    border-radius: 2.26562vw;
    height: 3.75vw;
    background-color: #00296A;
    border: none;
    margin: 0px auto 3vw auto;
    color: #FFFFFF;
    font-size: 0.88542vw;
    line-height: 1.04167vw;
    width: 14.27083vw;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    display: block;
    overflow: hidden;
    transition: color 0s 1.0s cubic-bezier(0.82, 0.2, 0.42, 1);
    ::before,::after{
        position: absolute;
        left: 0;
        width: 100%;
    }
    ::before {
        content: '';
        top: 50%;
        transform: translate(-101%, -50%);
        height: 70%;
        z-index: 1;
        background-image: url(${Logo});
        background-size: 55% 95%;
        background-repeat: no-repeat;
        transition: transform 1.5s cubic-bezier(0.82, 0.2, 0.42, 1);
    }       
    ::after {
        font-family: "Font Awesome 5 Free";
        content: attr(data-font);
        font-weight: 900;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #fff;
        opacity: 0;
        transition: opacity 0s 1.0s cubic-bezier(0.82, 0.2, 0.42, 1);
    }
    :hover::before {
        transform: translate(101%, -50%);
    }
    :hover::after {
        opacity: 1;
    }
    :hover{
        background-color: #00296A;
        color: transparent;
        transition: all 0.5s;
    }
    @media (max-width: 768px) {
        font-size: 0.80rem;
        height: 5.75vw;
        line-height: 2.04167vw;
        width: 18.2708vw;
     }
     @media (max-width: 425px) {
        font-size: 1rem;
        width: 48.2708vw;
        height: 13.75vw;
        line-height: 5.04167vw;
        
    }
`;
const ContenedorCards = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    place-content:  space-around;
    flex-wrap: wrap;
    margin-top: 2%;
`;

const ImagenCard = styled.img`
    max-width: 100%;
    width: 100%;
    height: auto;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
`;
const Card = styled.div`
    max-width: 30vw;
    max-height: 630px ;
    height: auto;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    margin-bottom: 3%;
    @media (max-width: 1024px) {
        max-height: 400px !important;
     }
     @media (max-width: 768px) {
        max-height: 400px !important;
        max-width: 40vw;
     }
     @media (max-width: 425px) {
        max-height: 400px !important;
        max-width: 80vw;
     }
    @media (max-width: 375px) {
        max-height: 400px !important;
    }
`;
const TituloCard = styled.h2`
    color: #00296a;
    padding-top: 2vw;
    padding-left: 0vw;
    text-align: center;
`;

const ModulosCards = styled.p`
    color: #00296a;
    padding-left: 0vw;
    text-align: center;
    margin-bottom: 2.5vw;
    text-align: center;

`;



export default Productos;