import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import Header from './componentes/Header';
import Inicio from './componentes/home/Inicio';
import Nosotros from './componentes/about-us/Nosotros';
import Servicios from './componentes/servicios/Servicios';
import ServiciosLinea from './componentes/servicios-linea/ServiciosLinea';
import Contacto from './componentes/contacto/Contacto';
import Footer from './componentes/Footer';
import Winsiaf from './componentes/productos/Winsiaf';
import Error404 from './componentes/Error404';
import RecursosHumanos from './componentes/productos/RecursosHumanos';
import RecursosFinancieros from './componentes/productos/RecursosFinancieros';
import RecursosMateriales from './componentes/productos/RecursosMateriales';

function App() {
  return (
    <BrowserRouter>
      <ContenedorPrincipal>
        <Header />
        <Main>
          <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="/servicios" element={<Servicios />} />
            <Route path="/servicios-linea" element={<ServiciosLinea />} />
            <Route path="/winsiaf" element={<Winsiaf />} />
            <Route path="/productos/recursos-humanos" element={ <RecursosHumanos /> } />
            <Route path="/productos/recursos-financieros" element={<RecursosFinancieros />} />
            <Route path="/productos/recursos-materiales" element={ <RecursosMateriales /> } />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Main>
        <Footer />
      </ContenedorPrincipal>
    </BrowserRouter>
    
  );
}

const ContenedorPrincipal = styled.div`
  width: 100%;
`;
const Main = styled.main`
  background-color: #fff;
`;

export default App;
