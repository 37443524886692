import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Banner from '../../assets/home/servicioslinea.jpg'
import Logo from '../../assets/logo.png'


const BannerServicios = () => {
    return ( 
        <ContenedorServicios>
            <Titulo>Servicios en Línea</Titulo>
            <NavLink to="/servicios-linea"><Btn data-font="&#xf1d8;">Ir a Servicios en Línea</Btn> </NavLink >
        </ContenedorServicios>
    );
}

const ContenedorServicios = styled.div`
    background-image: url(${Banner});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
    a{
        text-decoration: none;
    }
`;
const Titulo = styled.h1`
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    font-size: 3.5rem;
    padding-bottom: 2%;
`;

const Btn = styled.button`
    height: 3.75vw;
    background-color: #00296A;
    border: none;
    margin: 0 auto;
    color: #FFFFFF;
    font-size: 0.88542vw;
    line-height: 1.04167vw;
    width: 14.27083vw;
    text-align: center;
    border-radius: 2.26562vw;
    position: relative;
    display: block;
    padding: 10px;
    overflow: hidden;
    transition: color 0s 1.0s cubic-bezier(0.82, 0.2, 0.42, 1);
    ::before,::after{
        position: absolute;
        left: 0;
        width: 100%;
    }
    ::before {
        content: '';
        top: 50%;
        transform: translate(-101%, -50%);
        height: 70%;
        z-index: 1;
        background-image: url(${Logo});
        background-size: 55% 95%;
        background-repeat: no-repeat;
        transition: transform 1.5s cubic-bezier(0.82, 0.2, 0.42, 1);
    }       
    ::after {
        font-family: "Font Awesome 5 Free";
        content: attr(data-font);
        font-weight: 900;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #fff;
        opacity: 0;
        transition: opacity 0s 1.0s cubic-bezier(0.82, 0.2, 0.42, 1);
    }
    :hover::before {
        transform: translate(101%, -50%);
    }
    :hover::after {
        opacity: 1;
    }


    :hover{
        background-color: #00296A;
        color: transparent;
        transition: all 0.5s;
    }
    @media (max-width: 768px) {
        font-size: 0.80rem;
        height: 6.75vw;
        line-height: 2.04167vw;
        width: 16.2708vw;
     }
     @media (max-width: 425px) {
        font-size: 1rem;
        width: 48.2708vw;
        height: 13.75vw;
        line-height: 5.04167vw;
        margin-top: 10vw;
    }
`;
export default BannerServicios;