import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from '../../assets/logo.png'
import emailjs from 'emailjs-com';
import GoogleReCaptcha from 'react-google-recaptcha';



const Formulario = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [errores, setErrores] = useState({});
  const [token, setToken] = useState(null);


  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleTelefonoChange = (e) => {
    setTelefono(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      alert("Por favor, complete el reCAPTCHA");
      return;
    }
    if (name.trim() === '') {
      setNameError('El nombre es requerido');
    } else {
      setNameError('');
    }

    if (email.trim() === '') {
      setEmailError('El correo electrónico es requerido');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('El correo electrónico es inválido');
    } else {
      setEmailError('');
    }

    if (telefono.trim() === '') {
      setErrores((prevState) => ({
        ...prevState,
        telefono: 'El teléfono es obligatorio',
      }));
    } else if (!/^[0-9]+$/.test(telefono)) {
      setErrores((prevState) => ({
        ...prevState,
        telefono: 'El teléfono solo puede contener números',
      }));
    } else {
      setErrores((prevState) => ({ ...prevState, telefono: '' }));
    }

    if (message.trim() === '') {
      setMessageError('El mensaje es requerido');
    } else {
      setMessageError('');
    }

    if (!nameError && !emailError && !errores.telefono && !messageError && token) { 
      try {
        await emailjs.sendForm('service_n84vhok', 'template_fboyksf', e.target, 'bagprTEQKvrsEs6w9').then(e => {
          alert("Se ha enviado correctamente.");
          resetForm();
        })
      } catch (incorrecto) {
        alert("Error en el envio");
        console.log(incorrecto);
      }
    }else {
      alert("Error en el envio, Consulte los campos");
    }
  };
  const resetForm = () => {
    setName('');
    setEmail('');
    setTelefono('');
    setMessage('');
    setNameError('');
    setEmailError('');
    setMessageError('');
    setErrores({});
    setToken(null);
  }


  return (
    <ContenedorForm>
      <h2>¿Tienes alguna duda o comentario?</h2>
      <h1>Mándanos un mensaje</h1>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="name">Nombre</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={handleNameChange}
            placeholder="Escribe tu nombre"
          />
          {nameError && <Error>{nameError}</Error>}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="email">Correo electrónico</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Escribe tu correo electrónico"
          />
          {emailError && <Error>{emailError}</Error>}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="telefono">Teléfono</Label>
          <Input
            type="tel"
            id="telefono"
            name="telefono"
            value={telefono}
            onChange={handleTelefonoChange}
            placeholder="Escribe tu teléfono"
          />
          {errores.telefono && <Error>{errores.telefono}</Error>}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="message">Mensaje</Label>
          <Textarea
            id="message"
            name="message"
            value={message}
            onChange={handleMessageChange}
            placeholder="Escribe tu mensaje"
          />
          {messageError && <Error>{messageError}</Error>}
        </FormGroup>
        <GoogleReCaptcha
          sitekey="6LeV-dslAAAAAHmabJgDxGDX5Tvv32riSphXF24c"
          onChange={(token) => setToken(token)}
          />
        <BotonEnviar type="submit" data-font="&#xf1d8;">Enviar</BotonEnviar>
      </Form>
    </ContenedorForm>
  );

};

const ContenedorForm = styled.div`
  color: #00296a;
  margin-top: 2vw;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-left: 2vw;
  width: 90%;
`;

const FormGroup = styled.div`
  margin-bottom: 2vw;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 0.5vw;
`;

const Input = styled.input`
  padding: 1vw;
  font-size: 1.2vw;
  border: 2px solid #ddd;
  border-radius: 0.3vw;
  @media (max-width: 425px) {
    font-size: 3.2vw;
  }
`;

const Textarea = styled.textarea`
  padding: 1vw;
  font-size: 1.2vw;
  border: 2px solid #ddd;
  border-radius: 0.3vw;
  resize: vertical;
  height: 10vw;
  @media (max-width: 425px) {
    font-size: 3.2vw;
  }
`;

const BotonEnviar = styled.button`
border-radius: 2.26562vw;
    height: 3.75vw;
    background-color: #00296A;
    border: none;
    margin: 2vw auto;
    color: #FFFFFF;
    font-size: 0.88542vw;
    line-height: 1.04167vw;
    width: 14.27083vw;
    text-align: center;
    position: relative;
    display: block;
    padding: 10px;
    overflow: hidden;
    transition: color 0s 1.0s cubic-bezier(0.82, 0.2, 0.42, 1);
    ::before,::after{
        position: absolute;
        left: 0;
        width: 100%;
    }
    ::before {
        content: '';
        top: 50%;
        transform: translate(-101%, -50%);
        height: 70%;
        z-index: 1;
        background-image: url(${Logo});
        background-size: 55% 95%;
        background-repeat: no-repeat;
        transition: transform 1.5s cubic-bezier(0.82, 0.2, 0.42, 1);
    }       
    ::after {
        font-family: "Font Awesome 5 Free";
        content: attr(data-font);
        font-weight: 900;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #fff;
        opacity: 0;
        transition: opacity 0s 1.0s cubic-bezier(0.82, 0.2, 0.42, 1);
    }
    :hover::before {
        transform: translate(101%, -50%);
    }
    :hover::after {
        opacity: 1;
    }


    :hover{
        background-color: #00296A;
        color: transparent;
        transition: all 0.5s;
    }
    @media (max-width: 425px) {
        width: 34.2708vw;
        height: 13.75vw;
        line-height: 4.04167vw;
        font-size: 1rem;
    }

    
`;

const Error = styled.div`
  color: red;
  font-size: 1.2vw;
  margin-top: 0.5vw;
`;

export default Formulario;