import Humanos from '../assets/home/recursos.jpg';
import Materiales from '../assets/home/materiales.jpg';
import Financieros from '../assets/home/financieros.jpg';
import Viaticos from '../assets/home/viaticos.jpg';
import Proyectos from '../assets/home/proyectos.jpg';

export default [
    {
        id: 1,
        titulo: 'Recursos Humanos',
        modulo: 'Módulos: Nómina, Personal y Capacitación',
        imagen: Humanos,
        slug: 'recursos-humanos'
    },
    {
        id: 2,
        titulo: 'Recursos Financieros',
        modulo: 'Módulos: Contabilidad, Presupuestos y Tesorería',
        imagen: Financieros,
        slug: 'recursos-financieros'
    },
    {
        id: 3,
        titulo: 'Recursos Materiales',
        modulo: 'Módulos: Adquisiciones, Activo Fijo y Almacén',
        imagen: Materiales,
        slug: 'recursos-materiales'
    },
    {
        id: 4,
        titulo: 'Control de Viáticos',
        modulo: ' ',
        imagen: Viaticos,
        slug: 'control-de-viaticos'
    },
    {
        id: 5,
        titulo: 'Control de Proyectos de Terceros',
        modulo: ' ',
        imagen: Proyectos,
        slug: 'control-de-proyectos-de-tereros'
    }
];