import React from 'react';
import GoToTop from '../GoToTop';
import BannerLinea from './BannerLinea';
import Contenido from './Contenido';

const ServiciosLinea = () => {
    return ( 
        <>
            <BannerLinea />
            <Contenido />
            <GoToTop />
        </>
    );
}

export default ServiciosLinea;
