import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone,faEnvelope, faMapLocationDot  } from '@fortawesome/free-solid-svg-icons';



const Informacion = () => {
    return ( 
        <div>
            <Titulo>Información de nosotros.</Titulo>
            <Contenedor>
                <ContenedorInfo>
                    <p><span><FontAwesomeIcon icon={faPhone} /></span>Teléfono: 55 5277 5244</p>
                    <p><span><FontAwesomeIcon icon={faEnvelope} /></span>Correo: contacto@mwsoftware.com.mx</p>
                </ContenedorInfo>
                <Direccion><span><FontAwesomeIcon icon={faMapLocationDot} /></span>Dirección: Sur 75-A 4312, Viaducto Piedad, Iztacalco, 08200 Ciudad de México, CDMX</Direccion>
            </Contenedor>
            <ContenedorMapa>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.2110453281352!2d-99.12958188509371!3d19.403285286900942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1feec6c45e22d%3A0xf5fa4123a4c01f91!2sMW%20SOFTWARE%20S.A.%20DE%20C.V.!5e0!3m2!1ses!2smx!4v1683256015658!5m2!1ses!2smx"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </ContenedorMapa>
        </div>
     );
}

const Titulo = styled.h1`
    margin-top: 2vw;
    color: #00296a;
`;
const Contenedor = styled.div`
    width: 85%;
    @media (max-width: 768px) {
            text-align: center;
            margin: 0 auto;
        }
`;
const ContenedorMapa = styled.div`
    text-align: left;
    @media (max-width: 768px) {
            text-align: center;
            margin: 0 auto;
        }
    iframe{
        width: 600px;
        height: 500px;
        @media (max-width: 1024px) {
            height: 400px;
            width: 450px;
        }
        @media (max-width: 768px) {
            height: 400px;
            width: 600px;
            text-align: center;
            margin: 0 auto;
        }
        @media (max-width: 425px) {
            width: 320px;
        }
        @media (max-width: 375px) {
            width: 300px;
        }
    }
`;
const ContenedorInfo = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 4vw;
    p{
        text-align: left;
        display: inline-flex;
        span{
            width: 3vw;
            svg{
                color: #00296a;
            }
            @media (max-width: 425px) {
                width: 10vw;
            }
        }
    }
`;
const Direccion = styled.p`
    text-align: left;
    width: 100%;
    display: inline-flex;
    span{
            width: 3vw;
            svg{
                color: #00296a;
            }
            @media (max-width: 425px) {
                width: 10vw;
                padding-right: 1vw;
            }
        }
`;
export default Informacion;