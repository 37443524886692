import React from 'react';
import GoToTop from '../GoToTop';
import Contenido from './Contenido';

const Winsiaf = () => {
    return ( 
        <>
            <Contenido />
            <GoToTop />
        </>
     );
}
 
export default Winsiaf;