import React from 'react';
import styled from 'styled-components';
import Error from '../assets/404.jpg';
import GoToTop from './GoToTop';

const Error404 = () => {
    return (
        <>
            <Contenedor>
                <img src={Error} alt="Error 404"></img>
            </Contenedor>
            <GoToTop />
        </>
    );
}

const Contenedor = styled.div`
    text-align: center;
    margin: 0 auto;
    img{
        width: 60%;
    }
`;
export default Error404;