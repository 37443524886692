import React from 'react';
import styled from 'styled-components';
import GoToTop from '../GoToTop';
import BtnRegresar from './BtnRegresar';

const RecursosHumanos = () => {
    return ( 
        <div>
            <BtnRegresar />
            <Titulo>WIN-SIAF   Recursos Humanos</Titulo>
            <Informacion>
                <p>El <b>Sistema Integral de Recursos Humanos</b> es un programa que resuelve necesidades y requerimientos de información, integración y control en las áreas de Recursos Humanos, mismo que cumple con las Leyes, Normas y Lineamientos del Sector Público encargadas a establecer los requerimientos de uso de las mismas en las áreas que integren la parte de Personal.</p>
                <h2>ADMINISTRACION DE PERSONAL</h2>
                <p>El módulo de administración de personal permite tener el control de las plantillas de personal de la institución, de tal forma que existe un control de los empleados asociados a las plazas para generar la plantilla de personal a cualquier fecha de pago y centros de costo (áreas o unidades de adscripción), los movimientos del personal se hacen en función a las  plazas autorizadas por la entidad, es decir, se realizan altas (únicamente a una plaza vacante), bajas (quedando la plaza como vacante), cambio de plaza (de una plaza ocupada a una plaza vacante), e intercambio de plazas (cambio de plaza entre dos empleados). Entre los sub módulos se cuentan con los siguientes:</p>
                <Listado>
                    <ul>
                        <li><p>Control de tabuladores de sueldos</p></li>
                        <li><p>Control de Puestos</p></li>
                        <li><p>Plantillas de personal</p></li>
                        <li><p>Inventario de Recursos Humanos</p></li>
                        <li><p>Emisión de Nombramientos</p></li>                  
                    </ul>
                    <ul>
                        <li><p>Kardex de Personal</p></li>
                        <li><p>Kardes de Plantillas plazas</p></li>
                        <li><p>Hojas Únicas de servicio de los servidores públicos</p></li>
                        <li><p>Formatos de transparencia</p></li>
                    </ul>
                </Listado>
                <p>Dichas prestaciones pueden ser las autorizadas de forma ordinaria o normal de conformidad con el puesto o bien se puede llevar a cabo el registro de las prestaciones a las cuales tenga derecho el trabajador, derivado de una relación laboral bajo el esquema de personal sindicalizado y con ello se deberán incluir prestaciones por condiciones generales de trabajo, y estas que se deban integrar al sistema para su pago.</p>
                <p>Así mismo, se podrán registrar aquellas que la entidad o empresa adquiera por concepto otorgamiento de nuevas prestaciones, ampliaciones de estas o beneficios adicionales a las cuales otorgue derecho al trabajador o sus dependientes la dependencia, entidad y/o empresa.</p>
                <h2>ADMINISTRACION DE PRESTACIONES</h2>
                <p>En este Módulo se registran los diferentes tipos de incidencias variables y prestaciones adicionales a las cuales tiene derecho el trabajador.</p>
                <p>Dichas prestaciones pueden ser las autorizadas de forma ordinaria o normal de conformidad con el puesto o bien se puede llevar a cabo el registro de las prestaciones a las cuales tenga derecho el trabajador, derivado de una relación laboral bajo el esquema de personal sindicalizado y con ello se deberán incluir prestaciones por condiciones generales de trabajo, y estas que se deban integrar al sistema para su pago.</p>
                <p>Así mismo, se podrán registrar aquellas que la entidad o empresa adquiera por concepto otorgamiento de nuevas prestaciones, ampliaciones de estas o beneficios adicionales a las cuales otorgue derecho al trabajador o sus dependientes la dependencia, entidad y/o empresa.</p>
                <p>Administración de las condiciones Generales de Trabajo de la institución, tales como días económicas, empleado del mes, puntualizad y asistencia, prestaciones sociales y económicas entre otros</p>
                <p>El sistema contempla un kiosco institucional para los empleados de la institución</p>
                <h2>CONTROL DE ASISTENCIA</h2>
                <p><b>WIN-SIAF ASISTENCIA</b>: Interpretar, registrar y controlar las diferentes incidencias que el personal de la dependencia o entidad va generando en los lectores de asistencias, derivado de la relación laboral sujeta a un horario de trabajo asignado a cada uno de los empleados, tomando como base los artículos contenidos en las condiciones generales de trabajo y/o en el contrato colectivo o individual de trabajo vigente, así mismo, generar las incidencias que resulten con remuneración o descuento hacia la nómina a efecto de que se aplique en tiempo y forma, y se vea reflejado de manera clara, precisa y oportuna en los recibos de pago de cada empleado.</p>
                <p>El módulo de control de asistencias está basado en catálogos parametrizables por los propios usuarios, de tal forma que dependiendo de las propias necesidades de la Dependencia estos pueden generarse, modificarse o cambiarse de acuerdo a los requerimientos que se presenten.</p>
                <p><b>WIN-SIAF ASISTENCIA</b>, cuenta con el módulo de registros justificaciones de las incidencias que se generan en forma automática los procesos de interpretación de  los relojes biométricos que tenga la institución, toda vez que en esta se registran todos las incidencias, justificaciones o correcciones que generan los empleados, incluyendo periodos vacacionales, incapacidades profesionales y no profesionales, incidencias para pago (tiempo extraordinario, días festivos, guardias, sextos días, días económicos no gozados, reintegros de faltas improcedentes, etc.), días de goce en especie (día de las madres, día del trabajador, aniversario de la institución, etc.), licencias con goce de sueldo (por matrimonio, por defunción de familiar, por gravidez, por examen profesional, por comisión sindical, etc.), tolerancias y retardos, se puede incluir de ser necesario el horario inicial y final. Todo entre otras funcionalidades que se relación con el módulo de nómina y prestaciones.</p>
                <h2>NÓMINA.</h2>
                <p><b>WIN-SIAF NOMINA</b>. Es un sistema realiza pagos de nóminas ordinarias ya sea por períodos quincenales, mensuales o como sus requerimientos de operación lo soliciten, igualmente, realiza pagos extraordinarios tales como, aguinaldos, pago de prima vacacional, retroactivos de sueldos y pagos especiales, incluyendo la opción de pagos para personal contratado bajo el esquema de Honorarios Asimilados a Sueldos y personal eventual. Cuenta con los cambios fiscales del presente ejercicio, así como, el registro y control del tabulador de sueldos para Servidores Públicos Superiores, Mandos Medios y Homólogos y para el Personal Operativo, vigente al día de hoy, dicho tabulador está conformado por el Sueldo Tabular y la Compensación Garantizada.</p>
                <p>El sistema controla y calcula los impuestos de acuerdo a la Ley del I.S.R. (LISR) vigente, ya sea para cálculos quincenales, mensuales o anualizados acorde a la tabla de impuesto que se desee trabajar, se desglosan los cálculos del impuesto conformado por el artículo 96 (antes 113) y  10° Transitorio (antes 114), de la ley en mención, o bien se ajusta a la forma de calculo de la entidad siempre y cuando esta indique el procedimiento.</p>
                <p>Para efectos de la Ley del ISSSTE, IMSS o bien en algunos casos estatales o municipales, puede ser el ISSEMYM, el Sistema calcula, los diferentes porcentajes de cuotas y aportaciones conforme a la ley vigente en esta materia, sobre el sueldo base de cotización, incluyendo sueldos topados conforme al UMA o bien de acuerdo a sus necesidades por su legislación.</p>
                <p>Se incluye también la posibilidad de descuentos sobre días no laborados, ya sean faltas injustificadas, permisos sin goce de sueldo o días de incapacidad, cuando la entidad presente un ejemplo para su integración al sistema.</p>
                <p>Con relación a las Percepciones y Deducciones tanto fijas como variables, las denominamos DIPS (Deducción, Incidencia y Percepción), las cuales están contempladas en el sistema y son parametrizables de acuerdo a las necesidades de los usuarios dentro de la empresa o entidad.</p>
                <p>Respecto de las obligaciones patronales, el sistema <b>WINSIAF NÓMINA</b> genera todos los reportes a terceros, por ejemplo, pago de enteros al ISSSTE, Prestamos a mediano y corto plazo del ISSSTE (incluye contador), FOVISSSTE,, FONACOT, incluye los campos hoy día obligatorios del Número de Seguridad Social (NSS) y de la Clave Única de Registro de Población (CURP), etc., además de sus respectivos archivos planos o electrónicos en caso de requerirse para cualquier Dependencia o Institución a la que se tenga la obligación de reportar información, las características de grabación generalmente están determinadas por las instituciones que requieren dicho intercambio de información.</p>
                <p>En lo general estos son los informes que se emiten por el sistema, sin embargo; como todas y cada una de las herramientas internas del sistema, estás, se pueden personalizar e incluir los conceptos y variantes que el usuario desee de acuerdo a necesidades de cualquier ente público.</p>
                <h2>PRESUPESTO DEL CAPUTULO 1000</h2>
                <p><b>WINSIAF- PRESUPUESTO SERVICIOS PERSONALES</b>, permote la administración del presupuesto del capítulo 1000, desde la proyección presupuestal para la generación de las estructuras de una entidad pública, hasta el registros de los tiempos del presupuesto.</p>
                <Listado>
                    <ul>
                        <li><p>Presupuesto Original</p></li>
                        <li><p>Adecuaciones presupuestales (líquidas y compensadas)</p></li>
                        <li><p>Suficiencias presupuestales</p></li>
                        <li><p>Presupuesto comprometido</p></li>
                    </ul>
                    <ul>
                        <li><p>Ejercicio del Gasto</p></li>
                        <li><p>Reintegros presupuestales</p></li>
                        <li><p>Oficios de rectificación</p></li>
                        <li><p>Disponibilidad presupuestal.</p></li>
                    </ul>
                </Listado>
            </Informacion>
            <GoToTop />

        </div>
    );
}

const Titulo = styled.h1`
    margin-top: 2vw;
    text-align: center;
    color:#00296a; 
`;
const Informacion = styled.div`
    padding: 2vw;
    @media (max-width: 425px) {
            font-size: .80rem;
        }
    p{
        color: black;
        text-align: justify;

    }
    h2{
        text-align: center;
        color:#00296a; 
    }
    b{
        color:#00296a; 
    }
`;
const Listado = styled.div`
    display: flex;
    h2{
        color:#00296a; 
        text-align: center;
    }
    li{
        color:#00296a; 
        p{
            color: black;
        }
    }
    @media (max-width: 425px) {
        flex-direction: column;
    }
`;
export default RecursosHumanos;