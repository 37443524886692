import React from 'react';
import styled from 'styled-components';
import BannerServiciosImg from '../../assets/servicios-linea/bannerlinea.jpg';


const BannerLinea = () => {
    return ( 
        <ContenedorServiciosLinea>
            <Titulo>Servicios en Línea</Titulo>         
        </ContenedorServiciosLinea>
    );
}

const ContenedorServiciosLinea = styled.div`
    background-image: url(${BannerServiciosImg});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
`;

const Titulo = styled.h1`
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    font-size: 3.5rem;
    
`;
export default BannerLinea;