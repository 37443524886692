import React from 'react';
import styled from 'styled-components';
import Mision from '../../assets/nosotros/mision.png';
import Vision from '../../assets/nosotros/vision.png';

const CardsMisionVision = () => {
    return ( 
        <ContenedorCard>
            <CardMision>
                <CardFrente></CardFrente>
                <CardAtras>
                    <p>Desarrollar sistemas y proporcionar servicios de excelencia con base al grupo de especialistas que forman parte de <b>MW Software, S.A. de C.V</b> en el sector público y privado.</p>
                </CardAtras>
            </CardMision>

            <CardVision>
                <CardFrente></CardFrente>
                <CardAtras>
                    <p>Ser la empresa mexicana líder en el mercado en desarrollo e implementación de soluciones en Tecnología de información en el sector Público y Privado para lograr la satisfacción al 100% de nuestros clientes con la excelencia en el servicio de todos los integrantes de <br></br><b>MW Software, SA. De C.V</b></p>
                </CardAtras>
            </CardVision>
        </ContenedorCard>
     );
}
const ContenedorCard = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    place-content:  space-around;
    flex-wrap: wrap;
    margin-top: 2%;
    margin-bottom: 10vh;
    @media (max-width: 600px) {
        flex-direction: column;
    }
`;
const CardMision = styled.div`
    max-width: 40vw;
    height: 380px !important;
    border-radius: 0.25rem;
    margin-bottom: 2%;
    position: relative;
    transform-style: preserve-3d;
    transition: 2.0s ease;
    -webkit-perspective: 800;
    perspective: 800;
    box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.65);
    -webkit-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.65);
    -moz-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.65);
    div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
    }
    div:nth-child(1){
        background-image: url(${Mision});
    }
    :hover{
        transform: rotateY(180deg);
    }
    @media (max-width: 600px) {
        max-width: 80vw;
    }
`;

const CardVision = styled.div`
    max-width: 40vw;
    height: 380px !important;
    border-radius: 0.25rem;
    margin-bottom: 2%;
    position: relative;
    transform-style: preserve-3d;
    transition: 2.0s ease;
    -webkit-perspective: 800;
    perspective: 800;
    box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.65);
    -webkit-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.65);
    -moz-box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.65);
    div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
    }
    div:nth-child(1){
        background-image: url(${Vision});
    }
    :hover{
        transform: rotateY(180deg);
    }
    @media (max-width: 600px) {
        max-width: 80vw;
        margin-top:10vw;
    }
`;

const CardFrente = styled.div`
    background-repeat: no-repeat;
    background-size: 100% 100%;
`;

const CardAtras = styled.div`
    background-color: #8c9396;
    transform: rotateY(180deg);
    p{
        color: #fff;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 auto;
        height: 100%;
        b{
            display: contents;
            color: #00296A;
        }
    }
`;


export default CardsMisionVision;