import React from 'react';
import styled from 'styled-components';

const Contenido = () => {
    return (
        <ContenedorWinsiaf>
            <h1>WIN-SIAF</h1>
            <p><b>WIN-SIAF</b>  es  un  GRP par a las  instituciones  del  sector  público. Cumple con  la Ley  General  de  Contabilidad Gubernamental  en temas  de  armonización  contable para Recursos  Financieros ,  Recursos  Humanos  y  Recursos  Materiales  para cualquier ente público.</p>
            <p><b>WIN-SIAF</b> está diseñado para la Armonización de la Información Financiera, que permite una administración armonizada, robusta y transparente, facilitando la compatibilidad entre los tres niveles de gobierno, de acuerdo a lo establecido en el Título III, Capítulo I, Art. 16 al Art. 22, de la Ley General de Contabilidad Gubernamental (LGCG).</p>
            <p><b>WIN-SIAF</b> como GRP (Government Resource Planning) es un software de gestión integral que sistematiza la gestión presupuestal, contable y patrimonial de los recursos financieros, materiales y humanos del Gobierno federal, estatal y municipal lo que permite a cualquier orden de gobierno una mayor eficiencia en la gestión pública, además de transparencia en la información, mejor toma de decisiones e transparencia, confiabilidad en la información en la administración de los recursos financieros, recursos materiales y recursos humanos, para incorporar las mejores prácticas de la administración publica al que hacer gubernamental.</p>
            <p><b>WIN-SIAF</b> se compone en los siguientes múdulos: </p>
            <Modulos>
                <div>
                    <h3>Recursos Financieros</h3>
                    <ul>
                        <li><p>Control Presupuestal.</p></li>
                        <li><p>Control de Tesorería</p></li>
                        <li><p>Contabilidad Gubernamental</p></li>
                        <li><p>Viáticos</p></li>
                        <li><p>Recursos de terceros</p></li>
                    </ul>
                </div>
                <div>
                    <h3>Recursos Materiales</h3>
                    <ul>
                        <li><p>Adquisiciones</p></li>
                        <li><p>Almacenes</p></li>
                        <li><p>Activos Fijo</p></li>
                        <li><p>Servicios Generales</p></li>
                        <li className='d-hidden'><p></p></li>
                    </ul>
                </div>
                <div>
                    <h3>Recursos Humanos</h3>
                    <ul>
                        <li><p>Administración de personal</p></li>
                        <li><p>Administración de Prestaciones</p></li>
                        <li><p>Control de Asistencia</p></li>
                        <li><p>Nóminas</p></li>
                        <li><p>Administración de presupuesto capítulo 1000</p></li>
                    </ul>
                </div>
            </Modulos>
           
           
        </ContenedorWinsiaf>
    );
}

const ContenedorWinsiaf = styled.div`
    padding: 2vw;
    h1{
        color:#00296a; 
        margin: 2vw auto;
        text-align: center;
        font-size: 6em;
        @media (max-width: 425px)  {
            font-size: 3em;
        }
    }
    h3{
        color:#00296a; 
    }
    p b{
        color:#00296a; 
    }

    li{
        color:#00296a; 
        @media (min-width: 425px) and (max-width: 600px) {
            text-align: center;
            margin: 0 auto;
        }
        p{
            color: black;
        }
    }
`;
const Modulos = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 300px) and (max-width: 600px) {
            flex-direction: column;
            margin: 0 auto;
    }
`;



export default Contenido;