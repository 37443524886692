import React from 'react';
import styled from 'styled-components';
import BannerContactoImg from '../../assets/contacto/banner-contacto.jpg';


const BannerServicios = () => {
    return ( 
        <ContenedorContacto>
        </ContenedorContacto>
    );
}

const ContenedorContacto = styled.div`
    background-image: url(${BannerContactoImg});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
`;



export default BannerServicios;