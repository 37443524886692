import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from './../assets/logo.png'


const Header = () => {
    return ( 
        <header>
            <Nav>
                <NavMenu>
                    <NavLink to="/nosotros">Nosotros</NavLink>
                    <NavLink to="/servicios" >Servicios</NavLink>
                    <NavLink to="/"><Img src={ Logo } /></NavLink>
                    <NavLink to="/servicios-linea">Servicios en Línea</NavLink>
                    <NavLink to="/contacto">Contacto</NavLink>
                </NavMenu>
            </Nav>
        </header>

       
    ); 
}

const Nav = styled.nav`
  background: #00296A;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem calc((75vw - 1000px) / 2);
  z-index: 10;
`;

const NavLink = styled(Link)`
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
  padding: 0 1rem;
  width: 20vw;
  text-align: center;
  :nth-child(1){
    width: 20%;
  }
  :hover{
    text-decoration: none;
  }
  @media (min-width: 425px) and (max-width: 767px) {
    font-size: 1.3rem;
  }
  @media (max-width: 425px) {
    font-size: .85rem;
  }
  @media (max-width: 375px) {
    font-size: .80rem;
    }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
`;


const Img = styled.img`
    width: 65%;
    @media (max-width: 425px) {
      width: 20vw
    }
    @media (max-width: 375px) {
           width: 18vw;
    }
`;


export default Header;