import React from 'react';
import styled from 'styled-components';
import Logo from './../assets/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitterSquare, faGooglePlusSquare, faGithubSquare } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return ( 
        <FooterContainer>
            <div>
                <Img src={ Logo } />
            </div>
            <div>
                <Texto>Empresa 100% Mexicana, dedicada al desarrollo, consultoría y soporte de sistemas de información. Cuenta con una amplia gama de Sistemas Integrales de Administración.</Texto>
            </div>
            <ContainerRedes>
                <FontAwesomeIcon icon={faFacebook} size="2xlg" className='d-hidden'/>
            </ContainerRedes>
        </FooterContainer>
    );
}

const FooterContainer = styled.footer`
    background-color: #00296A;
    height: auto;
    display: flex;
    align-items: center;
    align-content: space-between;
    justify-content: center;
    justify-items: center;
    text-align: center;
    @media (max-width: 600px) {
        flex-direction: column;
    }
`;
const Img = styled.img`
    width: 10vw;
    padding: 2vw 0;
    @media (max-width: 425px) {
        width: 23vw;
    }
`;
const Texto = styled.p`
    color: #fff;
   
    @media (max-width: 1024px) {
        font-size: 0.85rem;
        padding-top: 1vw;
     }
     @media (max-width: 425px) {
        font-size: 0.55rem;
    }
    @media (max-width: 425px) {
        font-size: 0.85rem;
        padding: 0 3vw;
    }
`;
const ContainerRedes = styled.div`
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    align-items: center;
    justify-content: center;
    svg{
        color: #fff;
        height: 2em;
        width: 5vw;
    }
`;


export default Footer;