import Aem from '../assets/clientes/aem.png';
import Artf from '../assets/clientes/artf.png';
import Cmaegh from '../assets/clientes/cmaegh.png';
import Cnsns from '../assets/clientes/cnsns.png';
import Cofar from '../assets/clientes/cofar.png';
import Cofece from '../assets/clientes/cofece.png';
import Conafe from '../assets/clientes/conafe.jpg';
import Conanp from '../assets/clientes/conanp.png';
import Conuee from '../assets/clientes/conuee.png';
import Cpa from '../assets/clientes/cpa.png';
import Craech from '../assets/clientes/craech.jpg';
import Cre from '../assets/clientes/cre.png';
import Dif from '../assets/clientes/dif.png';
import Hraeo from '../assets/clientes/hraeo.jpg';
import Ifetel from '../assets/clientes/ifetel.png';
import Imer from '../assets/clientes/imer.png';
import Inali from '../assets/clientes/inali.jpg';
import Inapam from '../assets/clientes/inapam.jpg';
import Inapesca from '../assets/clientes/inapesca.png';
import Prodecon from '../assets/clientes/prodecon.jpg';
import Tfjfa from '../assets/clientes/tfjfa.png';

export default [
    {
        id: 1,
        cliente: 'Aem',
        imagen: Aem 
    },
    {
        id: 2,
        cliente: 'Artf',
        imagen: Artf 
    },
    {
        id: 3,
        cliente: 'Cmaegh',
        imagen: Cmaegh 
    },
    {
        id: 4,
        cliente: 'Cnsns',
        imagen: Cnsns 
    },
    {
        id: 5,
        cliente: 'Cofar',
        imagen: Cofar 
    },
    {
        id: 6,
        cliente: 'Cofece',
        imagen: Cofece 
    },
    {
        id: 7,
        cliente: 'Conafe',
        imagen: Conafe 
    },
    {
        id: 8,
        cliente: 'Conanp',
        imagen: Conanp 
    },
    {
        id: 9,
        cliente: 'Conuee',
        imagen: Conuee 
    },
    {
        id: 10,
        cliente: 'Cpa',
        imagen: Cpa 
    },
    {
        id: 11,
        cliente: 'Craech',
        imagen: Craech 
    },
    {
        id: 12,
        cliente: 'Cre',
        imagen: Cre 
    },
    {
        id: 13,
        cliente: 'Dif',
        imagen: Dif 
    },
    {
        id: 14,
        cliente: 'Hraeo',
        imagen: Hraeo 
    },
    {
        id: 15,
        cliente: 'Ifetel',
        imagen: Ifetel 
    },
    {
        id: 16,
        cliente: 'Imer',
        imagen: Imer 
    },
    {
        id: 17,
        cliente: 'Inali',
        imagen: Inali 
    },
    {
        id: 18,
        cliente: 'Inapam',
        imagen: Inapam 
    },
    {
        id: 19,
        cliente: 'Inapesca',
        imagen: Inapesca 
    },
    {
        id: 20,
        cliente: 'Prodecon',
        imagen: Prodecon 
    },
    {
        id: 21,
        cliente: 'Tfjfa',
        imagen: Tfjfa 
    }

];