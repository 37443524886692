import React from 'react';
import GoToTop from '../GoToTop';
import BannerServicios from './BannerServicios';
import Contenido from './Contenido';


const Servicios = () => {
    return ( 
        <>
            <BannerServicios />
            <Contenido />
            <GoToTop />
        </>
    );
}
 
export default Servicios;