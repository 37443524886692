import React from 'react';
import styled from 'styled-components';

const Contenido = () => {
    return ( 
        <ContenedorTexto>
            <p><b>Empresa 100% Mexicana</b>, dedicada tanto a los Servicios de Consultoría Gubernamental, como el desarrollo, instrumentación y soporte de sistemas de información Gubernamental. Cuenta con una amplia gama de Sistemas Integrales de Administración.</p>
            <p>El sistema <b>WINSIAF (Sistema Integral de Administración Financiera) </b>es un GRP para las organizaciones del sector público cumpliendo con la ley general de contabilidad gubernamental en temas de Armonización contable, Recursos Financieros, Recursos Humanos y Recursos Materiales para cualquier ente público.</p>
        </ContenedorTexto>
        
    );
}

const ContenedorTexto = styled.div`
    text-align: justify;
    width: 80%;
    margin: 0 auto;
    p{
        margin-top: 2vw;
        line-height: 1.8em;
        font-size: 1.2em;
        b{
            color: #00296A;
        }
    }
`;

export default Contenido;