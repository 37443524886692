import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Video from './../../assets/home/mundo.mp4';
import Mobile from './../../assets/home/home-mobile.jpg';

const BannerPrincipal = () => {
  const [autoplay, setAutoplay] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/Mobi/.test(userAgent)) {
      setAutoplay(false);
    }
  }, []);

  return (
    <ContenedorHome>
      <VideoFondo autoPlay={autoplay} muted loop>
        <source src={Video} type="video/mp4" />
      </VideoFondo>
      <Titulo>MW SOFTWARE, S.A. DE C.V.</Titulo>
    </ContenedorHome>
  );
};

const ContenedorHome = styled.div`
  position: relative;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  @media (max-width: 600px) {
    background-image: url(${Mobile});
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
`;

const VideoFondo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 600px) {
    display: none;
    visibility: hidden;
  }
`;

const Titulo = styled.h1`
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-size: 3.5rem;
  @media (max-width: 425px) {
    font-size: 3rem;
  }
`;

export default BannerPrincipal;
