import React from 'react';
import styled from 'styled-components';
import GoToTop from '../GoToTop';
import BtnRegresar from './BtnRegresar';
import ControlActivos from '../../assets/control-activos.png';


const RecursosMateriales = () => {
    return ( 
        <div>
        <BtnRegresar />
            <Titulo>WIN-SIAF  RECURSOS MATERIALES.</Titulo>
        <Informacion>
                <h2>ADQUISICIONES</h2>
                <p>El Módulo de Adquisiciones contempla los diferentes tipos de leyes y reglamentos, esto con el fin de contar con toda la normatividad requerida para cualquier tipo de contratación de bienes y/o servicios de acuerdo a las últimas modificaciones del Manual Administrativo de Aplicación General en Materia de Adquisiciones, Arrendamientos y Servicios del Sector Público.</p>
                <p>El módulo contempla el Programa anual de Adquisiciones (PAAS), el cual  esta parametrizado de tal manera que contempla todos los eventos que se generan en los diferentes procesos hasta llegar a la contratación para la adquisición, generación del PAAS,  hasta la emisión del Pedido o Contrato al proveedor(es) que resulten ganadores en el proceso de adquisición.</p>
                <p>Este modulo para desde los documentos de solicitud de bienes o servicios que se determina en las Requisiciones para que las diferentes áreas de la institución pública permitan solicitar a las áreas de Recursos materiales la solicitud de adquisición de bienes o servicios al interior del ente público. Así mismo se relaciona el área de Recursos Financieros para el control de las suficiencias presupuestales, el proceso de contratación, la formalización de los contratos y/o pedidos, generación del compromiso presupuestal, la entrega de bienes o servicios y el control de pagos de los compromisos presupuestales hasta el registro contable de todo el flujo del proceso.</p>
                <h2>ALMACEN DE CONSUMO Y ACTIVO FIJO</h2>
                <h3>Módulo de Activo Fijo</h3>
                <p>Características Generales.</p>
                <p>El sistema <b>MW-Recursos Materiales – Activo Fijo</b> resuelve en gran porcentaje las necesidades de información de los Almacenes de Consumo y Activo Fijo dado que es un desarrollo enfocado 100% para el Sector Público</p>
                <p>Permite controlar los procesos de Entrada y Salida de los almacenes de Bienes de Consumo y Activo Fijo.</p>
                <p>Una característica muy particular del sistema de Recursos Materiales es que cuenta con un control multi almacenes y multi líneas, lo que le da la facilidad al usuario de que con un mismo sistema pueda controlar todos los almacenes que se requieran, sin importar el número de líneas y artículos en los almacenes, para el caso de un órgano desconcentrado se puede manejar en forma independiente y consolidada los diferentes almacenes de los centros regionales o centros de costos.</p>
                <p>Concentra información de las Requisiciones Mensuales y Extraordinarias de bienes de consumo, así como de los resguardos a empleados, desde su asignación, sustitución y cédula de no adeudo.</p>
                <p><b>CONTROL DE BIENES ASOCIADOS Y ASOCIANTES</b>. Nuestro sistema cuenta con un control especial para los bienes asociados, es decir, que permite relacionar a los bienes que forman un bien en general, por ejemplo, el equipo de cómputo, por norma el cpu, el monitor, el mouse  y el teclado son cuatro bienes distintos, con CABMS diferente y con número de inventario individual, el los asocia entre si, por ejemplo el CPU que es el bien principal es el ASOCIANTE, y los otros bienes son los asociados como pueden, así pues podemos saber que bienes están asociados a un CPU.</p>
                <p>Otra característica especial de nuestro sistema es el  control de componentes de un bien, por ejemplo, en CPU los discos duros, con sus  números de serie, capacidad, fecha de instalación, etc, las memorias, algunas tarjetas, tipo de procesador, esto sirve para control más exacto de los activos de la institución</p>
                <p>Control de activos ejemplos:</p>
                <ContenedorImg>
                    <img src={ControlActivos} alt="Control de activos ejemplos"/>
                </ContenedorImg>
                <h2>Módulo: Almacén de Consumo</h2>
                <p>Dado que no es lo mismo la entrada de almacén  por compra a proveedor o por devolución de mercancía por parte de un área el sistema permite registrar diferentes tipos de documentos.</p>
                <p>Los documentos que afectan al almacén son configurados por medio del sistema, lo cual permite que se adecue sin problemas a la Institución sin necesidad de modificar la columna vertebral del  sistema.</p>
                <p>De acuerdo a la normatividad existen documentos que deben tener ciertas características específicas como es por ejemplo: La Nota de Débito, en el control de  activos el cual es utilizado cuando son Entradas al almacén por Donación de alguna otra dependencia, sin embargo dado que el control de documentos es configurado por el usuario se pueden registrar el Número de documentos que sean necesarios para su operación.</p>
                <p>El sistema cuenta con una lista de documentos oficiales que afectan el almacén, algunos de estos documentos son:</p>
                <ul>
                    <li><p>Compra a Proveedor.</p></li>
                    <li><p>Alta almacenaría.</p></li>
                    <li><p>Entrada de Almacén. </p></li>
                    <li><p>Nota de Débito</p></li>
                    <li><p>Nota de Crédito.</p></li>
                    <li><p>Requisición Mensual.</p></li>
                    <li><p>Requisición Extraordinaria.</p></li>
                    <li><p>Salida de Almacén.</p></li>
                    <li><p>Transferencia de Almacén.</p></li>
                    <li><p>Inventario Físico, etc.</p></li>
                </ul>


            </Informacion>
            <GoToTop />
        </div>
        
    );
}

const Titulo = styled.h1`
    margin-top: 2vw;
    text-align: center;
    color:#00296a; 
`;
const Informacion = styled.div`
    padding: 2vw;
    @media (max-width: 425px) {
            font-size: .80rem;
        }
    p{
        color: black;
        text-align: justify;
    }
    h2{
        text-align: center;
        color:#00296a; 
        padding-top: 1vw;
    }
    h3{
        color:#00296a; 
        text-decoration: underline;
    }
    b{
        color:#00296a; 
    }

`;
const ContenedorImg = styled.div`
    text-align: center;
    margin: 0 auto;
    img{
        width: 25%;
        text-align: center;
        margin: 0 auto;
        @media (max-width: 425px) {
            width: 80%;
        }
    }
`;
export default RecursosMateriales;