import React from 'react';
import styled from 'styled-components';
import BannerServiciosImg from '../../assets/servicios/bannerservicios.jpg';


const BannerServicios = () => {
    return ( 
        <ContenedorServicios>
            <Titulo>Servicios</Titulo>         
        </ContenedorServicios>
    );
}

const ContenedorServicios = styled.div`
    background-image: url(${BannerServiciosImg});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
`;

const Titulo = styled.h1`
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    font-size: 3.5rem;
    
`;

export default BannerServicios;