import React from 'react';
import GoToTop from '../GoToTop';
import BannerPrincipal from './BannerPrincipal';
import BannerServicios from './BannerServicios';
import Productos from './Productos';

const Inicio = () => {
    return ( 
        <>
                <BannerPrincipal/>
                <Productos />
                <BannerServicios />
                <GoToTop />
        </>
    );
}

export default Inicio;