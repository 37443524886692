import React from 'react';
import styled from 'styled-components';
import BannerNosotrosImg from '../../assets/nosotros/banner-nosotros.jpg';

const BannerNosotros = () => {
    return ( 
        <ContenedorNosotros>
            <Titulo>Nosotros</Titulo>         
        </ContenedorNosotros>
    );
}


const ContenedorNosotros = styled.div`
    background-image: url(${BannerNosotrosImg});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40vh;
`;

const Titulo = styled.h1`
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    font-size: 3.5rem;
    
`;


export default BannerNosotros;