import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


const BtnRegresar = () => {
    return ( 
        <Btn>
            <NavLink to="/"><ContenedorIcon><FontAwesomeIcon icon={faArrowLeft} /></ContenedorIcon>Atras</NavLink>
        </Btn>
     );
}

const Btn = styled.div`
    padding-top: 2vw;
    padding-left: 2vw;
    @media (max-width: 375px) {
        padding-left: 6vw;
    }
    a{
        display: inline-flex;
    }
`;
const ContenedorIcon = styled.span`
    width: 2vw;
    @media (max-width: 425px) {
        width: 3vw;
    }
`;
export default BtnRegresar;