import React from 'react';
import Slider from "react-slick";
import styled from 'styled-components';
import clientes from '../../data/clientes';


const Clientes = () => {
    // Configuración del carrusel
    const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true, // Agrega esta opción para que el carrusel se inicie automáticamente
    autoplaySpeed: 6000, // Agrega esta opción para ajustar la velocidad de desplazamiento
    responsive: [
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
        },
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
    ],
};

    return ( 
        <>
            <Subtitulo>Algunos de nuestros clientes:</Subtitulo>
            <CarouselContainer>
                <Slider {...settings}>
                    {clientes.map((cliente) => (
                    <div key={cliente.id}>
                        <Logo src={cliente.imagen} alt={cliente.cliente} />
                    </div>
                    ))}
                </Slider>
            </CarouselContainer>
        </>
    );
}

const Subtitulo = styled.h1`
    text-align: center;
    color:  #00296A;
    margin-bottom: 2vw;
`;

// Define el estilo de los logos de clientes
const Logo = styled.img`
    max-width: 50%;
    height: auto;
    margin-left: 4vw;
    @media (max-width: 425px) {
           margin: 0 auto;
           text-align: center;
    }
`;

// Define el estilo del carrusel
const CarouselContainer = styled.div`
    margin-bottom: 5vw;
    margin-left: 5vw;
    width: 90%;
    .slick-prev:before,
    .slick-next:before {
        color: #00296A;
    }
    .slick-dots {
        color: #00296A;
        @media (max-width: 425px) {
            margin-top: 5vh;
        }
        li button:before{
            color:  #00296A;
            @media (max-width: 425px) {
            margin: 0 auto;
            }
        }
    }
    @media (max-width: 425px) {
            .slick-next,.slick-prev{
                margin: 0 3vw;
            }
    }
`;


export default Clientes;