import React from 'react';
import styled from 'styled-components';
import Logo from '../../assets/logo.png'


const Contenido = () => {
    return ( 
        <div>
            <Titulo>Si ya eres cliente,</Titulo>
            <SubTitulo >accede a nuestra mesa de ayuda.</SubTitulo>
            <a href="https://mwsoftware.com.mx/winsiaf/hd/index.php" target="_blank" rel="noreferrer"><Btn data-font="&#xf0fa;">Ir a la mesa de ayuda</Btn></a>
        </div>
    );
}

const Titulo = styled.h1`
    padding-top: 2vw;
    color:#00296a;
    text-align: center;
    margin: 0 auto;
`;
const SubTitulo = styled.h1`
    color:#00296a;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 2vw;
`;
const Btn = styled.button`
    border-radius: 2.26562vw;
    height: 3.75vw;
    background-color: #00296A;
    border: none;
    margin: 0 auto;
    color: #FFFFFF;
    font-size: 0.88542vw;
    line-height: 1.04167vw;
    width: 14.27083vw;
    text-align: center;
    margin-bottom: 5vw;
    position: relative;
    display: block;
    padding: 10px;
    overflow: hidden;
    transition: color 0s 1.0s cubic-bezier(0.82, 0.2, 0.42, 1);
    ::before,::after{
        position: absolute;
        left: 0;
        width: 100%;
    }
    ::before {
        content: '';
        top: 50%;
        transform: translate(-101%, -50%);
        height: 70%;
        z-index: 1;
        background-image: url(${Logo});
        background-size: 55% 95%;
        background-repeat: no-repeat;
        transition: transform 1.5s cubic-bezier(0.82, 0.2, 0.42, 1);
    }       
    ::after {
        font-family: "Font Awesome 5 Free";
        content: attr(data-font);
        font-weight: 900;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #fff;
        opacity: 0;
        transition: opacity 0s 1.0s cubic-bezier(0.82, 0.2, 0.42, 1);
    }
    :hover::before {
        transform: translate(101%, -50%);
    }
    :hover::after {
        opacity: 1;
    }


    :hover{
        background-color: #00296A;
        color: transparent;
        transition: all 0.5s;
    }
    @media (max-width: 768px) {
        font-size: 0.80rem;
        height: 6.75vw;
        line-height: 2.04167vw;
        width: 20.2708vw;
     }
     @media (max-width: 425px) {
        font-size: 1rem;
        width: 34.2708vw;
        height: 13.75vw;
        line-height: 5.04167vw;
    }
`;

export default Contenido;