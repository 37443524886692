import React from 'react';
import GoToTop from '../GoToTop';
import BannerNosotros from './BannerNosotros';
import CardsMisionVision from './CardsMisionVision';
import Clientes from './Clientes';
import Contenido from './Contenido';

const Nosotros = () => {
    return ( 
        <>
            <BannerNosotros />
            <Contenido />
            <CardsMisionVision />
            <Clientes />
            <GoToTop />
        </>

     );
}
 
export default Nosotros;